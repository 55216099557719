.m {
  @apply max-w-[500px] mx-auto pt-5 px-5 pb-8 h-full hidden text-center;

  &.mA {
    @apply block;
  }

  .points {
    @apply flex justify-center;

    > div {
      @apply py-0 px-[50px] gap-[6px] flex-grow-0 flex flex-col items-center;

      &:nth-child(1) {
        @apply border-r-2 border-r-gray-100;
      }

      &:nth-child(2) {
        @apply border-l-2 border-l-gray-100;
      }
    }
  }

  .point {
    @apply text-[20px] w-[65px] h-[54px] flex items-center justify-center bg-no-repeat bg-center text-white font-bold;
    background-image: url("/assets/images/heart.svg");
  }

  .label {
    @apply text-[12px] font-quicksand font-bold;
    line-height: 1.3;
  }
}
