* {
  @media (min-width: 768px) {
    scrollbar-width: 10px;
    scrollbar-color: #000;

    &::-webkit-scrollbar {
      width: 2px;
      height: 2px;
    }

    &::-webkit-scrollbar-track {
      background: rgba(#000, 0.1);
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(#000, 0.7);
    }
  }
}


