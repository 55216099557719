.badge {
  @apply absolute flex items-center justify-between gap-1 text-[12px] px-[7px] py-0.5 rounded-full text-white bottom-1/2;

  &.comment {
    @apply right-1/2 bg-green -translate-x-1;
  }

  &.points {
    @apply left-1/2 bg-purple translate-x-1;
  }

  .tasks__link--inner & {
    @apply -translate-y-3;
  }
}
