.m {
  @apply bg-white border-dark;
}

.r {
  @apply gap-[50px] flex;

  &:nth-child(1) {
    @apply text-[20px] font-bold;
  }

  &:nth-child(2) {
    @apply pt-6;
  }

  &:nth-child(n + 3) {
    @apply pt-5 mt-5 relative before:h-[2px] before:absolute before:top-0 before:-left-[20px] before:-right-[20px] before:bg-dark;
  }

  > span {
    @apply mr-auto flex-grow;
  }

  > em,
  > :global(.field-group) {
    @apply w-[22px] flex-shrink-0 flex justify-center text-center items-center;
  }
}
