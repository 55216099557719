.m {
  position: fixed;
  top: 60px;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #fff;
}

.c {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
}
