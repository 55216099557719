.c {
  iframe,
  img,
  table,
  video {
    @apply max-w-full my-5;
  }

  table {
    @apply border;
  }

  tr {
    &:not(:first-child) {
      td {
        @apply border-t;
      }
    }
  }

  td,
  th {
    @apply text-left px-2;

    &:not(:first-child) {
      @apply border-l;
    }
  }

  th {
    @apply py-4 font-bold border-b-2;
  }

  td {
    @apply py-1;
  }

  :global(.table-responsive) {
    @apply w-full overflow-x-auto;
  }
}
