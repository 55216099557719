.m {
  @apply -mx-[30px] px-[30px] my-5 overflow-x-hidden overflow-y-auto flex-1;
}

.entry {
  @apply mt-5 p-5 rounded-xl;

  &.my {
    @apply bg-white ml-10 animate-myEntry;
  }

  &.mod {
    @apply bg-blue-base text-white mr-10 animate-modEntry;
  }

  .avatar {
    @apply w-[20px] h-[20px] float-left mr-1 rounded-full;
  }
}
