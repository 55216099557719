.m {
  :global(.box) {
    @apply bg-white border-dark;
  }
}

.s {
  @apply mt-6 mb-7;
}

.bt {
  @apply block mb-2 text-sm;
}

.tags {
  @apply gap-[5px] flex mb-2;

  span {
    @apply py-[2px] px-[10px] rounded-full text-sm font-bold;
  }
}

.avatar {
  @apply w-[20px] h-[20px] float-left rounded-full mr-1;
}

.messages {
  :global(.box) {
    margin-bottom: 15px;

    &:last-child {
      @apply mb-0;
    }
  }
}

.f {
  @apply mt-5;

  :global(.form-field) {
    @apply mb-0;
  }
}

.b {
  @apply text-white flex gap-1 items-center ml-auto;
  @apply px-2 #{!important};

  & + & {
    @apply ml-0;
  }
}

.mB {
  @apply bg-green;
}

.pB {
  @apply bg-purple;
}
