.m {
  margin-bottom: 15px;

  &:last-child {
    @apply mb-0 #{!important};
  }
}

.t {
  @apply mt-5;

  .avatar {
    @apply float-left mr-1;
  }
}

.l {
  @apply mt-5 pt-5 relative before:absolute before:h-[3px] before:h-[3px] before:top-0 before:-right-5 before:-left-5 before:bg-dark;
}

.c {
  @apply mt-[10px] flex gap-[10px] items-center;
}

.avatar {
  @apply w-[20px] h-[20px] rounded-full;
}

.b {
  @apply flex gap-2 items-center text-lg;

  em {
    font-size: 20px;
  }
}

.f {
  @apply mt-5;

  :global(.form-field) {
    @apply mb-0;
  }
}
