@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply bg-gray-100 font-openSans text-base;
  }

  :root {
    --color-primary: #0b1c2d;
    --shadow: #222;
  }
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h6,
.h6 {
  @apply font-quicksand;
}
h1,
.h1 {
  @apply text-h1;
}

h2,
.h2 {
  @apply text-h2;
}

h3,
.h3 {
  @apply text-h3;
}

h4,
.h4 {
  @apply text-h4;
}

h5,
.h5 {
  @apply font-openSans text-h5;
}

p:not(:last-child) {
  margin-bottom: 1.5em;
}

.btn {
  @apply inline-flex justify-center text-h4 font-bold uppercase py-[1em] px-[2em] rounded-full;
}

.btn-primary {
  @apply bg-blue-base text-white;
}

.btn-secondary {
  @apply bg-dark text-white;
}

.btn-link {
  @apply underline;
}
