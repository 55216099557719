@font-face {
  font-family: "icomoon";
  src: url("fonts/icomoon.eot?uf93ud");
  src: url("fonts/icomoon.eot?uf93ud#iefix") format("embedded-opentype"),
    url("fonts/icomoon.ttf?uf93ud") format("truetype"), url("fonts/icomoon.woff?uf93ud") format("woff"),
    url("fonts/icomoon.svg?uf93ud#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-email:before {
  content: "\e91c";
}
.icon-selfie:before {
  content: "\e91b";
}
.icon-upload-file:before {
  content: "\e90f";
}
.icon-info:before {
  content: "\e90e";
}
.icon-accepted:before {
  content: "\e900";
}
.icon-attach:before {
  content: "\e901";
}
.icon-book:before {
  content: "\e902";
}
.icon-camera:before {
  content: "\e903";
}
.icon-mobile:before {
  content: "\e904";
}
.icon-chat:before {
  content: "\e905";
}
.icon-check:before {
  content: "\e906";
}
.icon-close:before {
  content: "\e907";
}
.icon-comment:before {
  content: "\e908";
}
.icon-cross:before {
  content: "\e909";
}
.icon-edit:before {
  content: "\e90a";
}
.icon-trash:before {
  content: "\e90b";
}
.icon-hamburger:before {
  content: "\e90c";
}
.icon-hourglass:before {
  content: "\e90d";
}
.icon-list:before {
  content: "\e910";
}
.icon-notification:before {
  content: "\e911";
}
.icon-photo:before {
  content: "\e912";
}
.icon-play:before {
  content: "\e913";
}
.icon-point:before {
  content: "\e914";
}
.icon-question:before {
  content: "\e915";
}
.icon-send:before {
  content: "\e916";
}
.icon-stopper:before {
  content: "\e917";
}
.icon-store:before {
  content: "\e918";
}
.icon-video:before {
  content: "\e919";
}
.icon-board:before {
  content: "\e91a";
}
