.m {
  @apply max-w-[500px] mx-auto pt-[70px] px-5 pb-5 font-bold h-full hidden;

  &.mA {
    @apply block;
  }

  :global .box {
    @apply mb-[30px] bg-white text-dark border-white;

    li {
      &:nth-child(n + 3) {
        @apply border-t-gray-100;
      }
    }

    .btn {
      @apply w-full;
    }
  }

  li {
    &:first-child {
      padding-bottom: 4px !important;
    }

    &:not(:first-child) {
      @apply pt-3;
    }

    &:not(:last-child) {
      @apply pb-3;
    }

    &:nth-child(n + 3) {
      @apply border-t-2 border-t-dark;
    }
  }

  .user {
    @apply flex justify-between items-center;
  }

  .name {
    @apply text-[20px] block font-quicksand;
    line-height: 1.2;
  }

  .email {
    @apply text-[10px] font-normal block;
  }

  .avatar {
    @apply w-[60px] h-[60px] rounded-full;
  }
}

.c {
  @apply text-[16px] p-5 text-dark absolute top-0 right-0 cursor-pointer transition;

  &:hover {
    transform: scale(1.2);
  }
}

.notification {
  @apply flex cursor-pointer;

  .icon {
    @apply flex items-center justify-center w-[40px] h-[40px] mr-3 rounded-full text-white;
  }
}
