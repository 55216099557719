.m {
  display: grid;
  grid-template-rows: auto 64px;
  height: 100%;
}

.carousel {
  overflow: hidden;
  overflow-y: auto;

  &__inner {
    transition: transform 0.3s;
    display: flex;
  }

  &__item {
    display: inline-flex;
    align-items: center;
    width: 100%;
    flex: 0 0 100%;
    flex-direction: column;
    gap: 40px;

    > div {
      padding: 0 20px;
    }
  }

  &__dots {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;

    &__item {
      width: 12px;
      height: 12px;
      background: black;
      border: 2px solid;
      border-radius: 50%;
      transition: 0.2s ease-out;
    }

    &__item.active ~ &__item {
      background: transparent;
    }
  }
}
