.tasks {
  @apply grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 150px;

  &__link {
    @apply flex items-center justify-center w-[70px] h-[70px] m-0 bg-white border-[3px] shadow-default border-dark rounded-full uppercase font-bold;

    &.completed {
      @apply bg-white border-dark text-dark;
    }

    &.active {
      @apply w-[90px] h-[90px] bg-blue-base border-blue-base text-white;
    }

    &.inactive {
      @apply bg-white border-gray-200 text-gray-200 pointer-events-none;
      --shadow: #979797;
    }

    &--inner {
      @apply relative flex items-center justify-center grow-0 h-full before:absolute;

      &:last-child {
        &:before {
          content: unset;
        }
      }

      &::before {
        top: var(--nth-top);
        left: var(--nth-left);
        right: var(--nth-right);
        bottom: var(--nth-bottom);
        width: var(--nth-width);
        height: var(--nth-height);
        background: var(--nth-bg, #979797);
        z-index: -1;
      }

      &:nth-child(4n + 1),
      &:nth-child(4n + 4) {
        grid-column: 2;
      }

      &:nth-child(4n + 2),
      &:nth-child(4n + 3) {
        grid-column: 1;
      }

      &:nth-child(4n + 2),
      &:nth-child(4n + 4) {
        --nth-top: -50%;
        --nth-left: calc(50% - 1px);
        --nth-height: 100%;
        --nth-width: 2px;
      }

      &:nth-child(4n + 1),
      &:nth-child(4n + 3) {
        --nth-height: 2px;
        --nth-width: 100%;
        --nth-top: calc(50% - 1px);
      }

      &:nth-child(4n + 1) {
        --nth-left: -50%;
      }

      &:nth-child(4n + 3) {
        --nth-left: 50%;
      }

      &.completed {
        --nth-bg: #222222;
      }
    }

    > em {
      @apply text-xl;
    }
  }
}
