.t {
  @apply text-center relative;

  &:not(:first-child) {
    &.inactive:after {
      @apply bg-gray-200;
    }

    &:after {
      @apply w-[3px] h-[20px] absolute bottom-0 left-1/2 bg-dark;
      content: var(--tw-content);
    }
  }

  &.active {
    .title {
      font-size: 1rem;
    }
  }

  &.inactive {
    margin-top: 25px;

    :global(.box) {
      @apply pt-9;
    }

    .title {
      font-size: 1rem;
    }

    .i {
      @apply border-gray-200;
    }
  }

  &.completed {
    margin-top: 25px;

    .title {
      font-size: 0.75rem;
    }

    :global(.box) {
      @apply py-9;
    }
  }

  .title {
    @apply mb-1;
  }

  :global(.badge) {
    @apply static;
  }
}

.title {
  @apply font-bold font-quicksand;
}

.type {
  @apply uppercase font-semibold;
  font-size: 0.625rem;
  letter-spacing: 0.05em;
}

.i {
  @apply w-[50px] h-[50px] rounded-full flex items-center justify-center bg-white text-dark border-[3px] border-dark absolute top-0 left-1/2 -translate-x-1/2 -translate-y-1/2;
}
