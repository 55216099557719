.i {
  @apply relative flex flex-1 gap-[8px] items-center flex-col;

  em {
    font-size: 20px;
  }

  span {
    font-size: 10px;
  }

  .badge {
    @apply absolute flex items-center justify-center left-1/2 w-[15px] h-[15px] rounded-full bg-blue-base;
  }
}
