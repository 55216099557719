.m {
  @apply border-dark bg-white text-center;

  img {
    @apply w-[80px] h-[80px] rounded-full mx-auto mb-4;
  }

  h2 {
    @apply mb-[10px] text-h3 font-bold;
  }

  :global(.btn) {
    @apply mt-5;

    &[disabled] {
      @apply bg-gray-200;
    }
  }
}

.points {
  @apply mt-3 text-h2 font-bold flex items-center justify-center gap-2;
}

.modal {
  @apply fixed top-1/2 left-1/2 w-full px-[20px] -translate-y-2/4 -translate-x-2/4 text-center ease-in duration-200;

  :global(.box) {
    @apply  bg-white border-0 max-w-[300px] mx-auto;
  }

  &:after {
    @apply fixed top-1/2 left-1/2 w-screen h-screen bg-black/[.5] -z-[1] -translate-y-2/4 -translate-x-2/4;
    content: ""
  }

  &:not(.isConfirmation) {
    @apply opacity-0 invisible;
  }
}

.confirm {
  @apply bg-white border-dark fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 px-5 py-4;
}
