.m {
  @apply h-[100vh] grid;
  grid-template-rows: 60px auto 60px;
  grid-template-columns: 100%;
}

.h {
  @apply sticky bg-white flex items-center justify-center top-0 px-5;
  z-index: 1;
}

.c {
  @apply max-w-[500px] w-full mt-5 mb-10 mx-auto px-5;
}

.f {
  position: sticky;
  @apply sticky bg-dark text-white bottom-0 flex justify-between items-center text-center;
}

.n {
  @apply fixed top-0 left-0 right-0 bottom-0 transition bg-blue-base text-white;
  z-index: 2;

  &:not(.nOpen) {
    @apply opacity-0 translate-y-full;
  }
}

.tn {
  @apply fixed top-[60px] left-0 right-0 transition bg-white rounded-b-[10px] shadow-default;

  &:not(.nOpen) {
    @apply opacity-0 -translate-y-full;
  }
}

.ov {
  @apply absolute w-full px-[20px] top-[80px] left-1/2 -translate-x-2/4;

  &:not(.nOpen) {
    @apply opacity-0 -translate-y-80;
  }
}

.modal {
  @apply fixed top-1/2 left-1/2 w-full px-[20px] -translate-y-2/4 -translate-x-2/4 text-center ease-in duration-200;

  :global(.box) {
    @apply  bg-white border-0;
  }

  &:after {
    @apply fixed top-1/2 left-1/2 w-screen h-screen bg-black/[.5] -z-[1] -translate-y-2/4 -translate-x-2/4;
    content: ""
  }

  &:not(.nOpen) {
    @apply opacity-0 invisible;
  }
}
