.form {
  &-field {
    @apply flex flex-wrap mb-5 items-center;

    &__button {
      .form-input {
        @apply flex-1 mr-5;
      }
    }
  }

  &-label {
    @apply w-full text-h5 mb-[5px] font-bold;
  }

  &-input {
    &:focus {
      @apply border-2 rounded-[10px] shadow-none;
    }

    @apply border-2 rounded-[10px] w-full;
  }

  &-checkbox,
  &-radio {
    @apply appearance-none w-5 h-5 border border-2 outline-none duration-200 cursor-pointer;
    box-shadow: none !important;

    ~ label,
    .label {
      @apply inline-block w-auto my-0 ml-3;
    }
  }

  &-checkbox {
    @apply transition rounded-sm bg-white checked:bg-blue-base checked:border-blue-base focus:ring-0 align-top bg-no-repeat bg-center bg-contain;
  }

  &-radio {
    @apply transition-[background] text-white checked:border-blue-base checked:hover:border-blue-base checked:focus:border-blue-base;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTYgMTYiIGZpbGw9IiM0OTc1ZWUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGNpcmNsZSBjeD0iOCIgY3k9IjgiIHI9IjUiLz48L3N2Zz4K") !important;
    background-repeat: no-repeat;
    background-position: center;

    &:not(:checked) {
      background-size: 0 0;
    }
  }

  &-radio {
    @apply w-4 h-4 text-blue-base bg-transparent border-dark focus:ring-dark focus:ring-2;
  }

  &-range {
    @apply appearance-none w-full h-3 bg-dark rounded-lg appearance-none cursor-pointer dark:bg-dark;

    &__inner {
      @apply bg-white rounded-lg pt-5 px-2;
    }
  }

  &-toggle {
    @apply w-12 h-7 bg-white border-2 border-dark rounded-full dark:focus:ring-blue-base dark:bg-dark peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-1/2 after:-translate-y-1/2 after:left-[4px] after:bg-dark after:border-gray-100 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-blue-base peer-checked:bg-blue-base peer-checked:border-blue-base peer-checked:after:bg-white;
  }
}
