.e {
  @apply relative w-[180px] h-[180px] mx-auto mt-5 bg-white pt-5 pb-6 flex flex-col justify-center items-center mb-3 rounded-full cursor-pointer bg-no-repeat bg-center bg-contain;

  &:not(.t) {
    @apply border-2 border-gray-200 border-dotted;
  }

  img {
    @apply rounded-full;
  }
}
