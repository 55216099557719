.btn {
  transition: .1s ease-out;

  &[disabled] {
    @apply disabled:opacity-75;
  }

  &-primary {
    --btn-hover: #3562dd;
  }

  &-secondary {
    --btn-hover: #3562dd;
  }

  &-link {
    --btn-hover: transparent;
  }

  &:hover  {
   @apply bg-btnHover;
  }
}


.like {
  @apply cursor-pointer;

  &:hover .icon {
    @apply text-blue-base;
  }
}
