.box {
  @apply p-5 border-[3px] rounded-[10px] shadow-default;
  margin-bottom: 20px;

  &.active {
    @apply bg-blue-base border-blue-base text-white;
  }

  &.completed {
    @apply bg-white border-dark text-dark;
  }

  &.inactive {
    @apply bg-white border-gray-200 text-gray-200 pointer-events-none;
    --shadow: #979797;
  }

  &__title {
    @apply mb-5;
  }

  &__projects {
    @apply mb-[15px] gap-[10px] flex justify-between;

    &--details {
      @apply rounded-[10px] flex flex-col items-center px-5 py-3 flex-1;

      &.active {
        @apply bg-blue-900;
      }

      &.inactive {
        @apply border border-dashed;
      }

      .icon {
        @apply text-h2;
      }

      span {
        @apply font-bold;
      }
    }
  }
}
